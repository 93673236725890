import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#63b8ff',
      main: '#0989e3',
      dark: '#005db0',
    },
  },
  zIndex: {
    drawer: 998,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '64px',
        },
      },
    },
    // DISABLING AUTOCOMPLETE!
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px white inset',
              WebkitTextFillColor: '#fff',
            },
          },
        },
      },
    },
  },
});
